import { ChangeEvent, createRef, KeyboardEvent, RefObject, useEffect, useMemo, useState } from 'react';

import { IValidationError } from '%/validation/d';
import { InputError } from '~/shared/components/controls/error';

import styles from './digit-code-input.module.styl';

type ISmsCodeInputProps = {
  name: string
  value?: string|null
  length?: number
  onChange?: (event:ChangeEvent<HTMLInputElement>, value:string) => void
  errors?: IValidationError
}

export const DigitCodeInput:React.FC<ISmsCodeInputProps> = ({
  name, value: initial = '', length = 4, onChange, errors
}) => {
  const [value, setValue] = useState(initial);
  const digitRefs:RefObject<HTMLInputElement>[] = useMemo(
    () => Array(length).fill(0).map(() => createRef())
  , [length]);

  const handleChange = (event:ChangeEvent<HTMLInputElement>):void => {
    const cpEvent = event;
    const code = digitRefs
      .map((inpRef:RefObject<HTMLInputElement>) => inpRef.current?.value)
      .filter((val?:string) => typeof val === 'string')
      .join('');

    setValue(code.replace(/[^\d]/, ''));
    onChange?.(cpEvent, code.replace(/[^\d]/, ''));
  };

  const handleKeyDown = (event:KeyboardEvent<HTMLInputElement>):void => {
    // baskspace keycode
    if (event.which === 8) {
      const newValue = value.slice(0, value.length - 1);
      setValue(newValue);
      onChange(null, newValue);
    }
  };

  useEffect(() => {
    setValue(initial);
  }, [initial]);

  useEffect(() => {
    value && digitRefs[value.length - 1].current.blur();
    value.length < length && digitRefs[value.length].current.focus();
  }, [value, length]);

  return (
    <div className={styles.root}>
      <input type="hidden" name={name} value={value}/>
      <div className={styles.controls}>
        { Array(length).fill(0).map((el, i:number) => (
          <input
            type="text"
            ref={digitRefs[i]}
            key={`code_digit_${i}`}
            onChange={handleChange}
            onKeyDown={handleKeyDown}
            value={value[i] || ''}
          />
        )) }
      </div>
      { errors && <InputError errors={errors} className={styles.centered}/> }
    </div>
  );
};