import { Navigate, useLocation } from 'react-router-dom';

import { getViewAbsoluteUrl } from '~/core/views';
import { Auth } from '~/shared/components/auth';
import { PageLayout } from '~/shared/components/page-layout';
import { useUser } from '~/shared/hooks/use-user';

const Login:React.FC = () => {
  const location = useLocation();
  const { user } = useUser();

  return <PageLayout centeredContent fs renderFooter={false}>
    { user?.role ?
      <Navigate to={location?.state?.from?.pathname || getViewAbsoluteUrl('profile')} replace/> :
      <Auth/>
    }
  </PageLayout>;
};

export default Login;