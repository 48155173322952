import { ChangeEvent, useCallback, useEffect, useState } from 'react';

import { IAuthRequestBody } from '%/entities/auth';
import { authValidate } from '%/validation/auth';
import { DigitCodeInput } from '~/shared/components/controls/digit-code-input';
import { Form } from '~/shared/components/controls/form';
import { IFormRenderProps } from '~/shared/components/controls/form/d';
import { Input } from '~/shared/components/controls/input';
import { Delimeter } from '~/shared/components/delimeter';
import { useNotification } from '~/shared/components/notification';
import { Paragraph } from '~/shared/components/paragraph';
import { TextLink } from '~/shared/components/text-link';
import { useTimer } from '~/shared/hooks/use-timer';
import { useUser } from '~/shared/hooks/use-user';
import { useUserActions } from '~/shared/hooks/use-user-actions';
import { pluralize } from '~/shared/tools/pluralize';

import styles from './auth.module.styl';

const FORM_ID = 'authFormProcess';

export const Auth:React.FC = () => {
  const { updateUser } = useUser();
  const { login } = useUserActions();
  const timer = useTimer(0);
  const { addSendingId, removeSendingId } = useNotification();

  const [authError, setAuthError] = useState('');
  const [phone, setPhone] = useState('');
  const [code, setCode] = useState('');

  const [view, setView] = useState('password');
  const showPhoneView = useCallback(() => setView('phone'), []);
  const showPasswordView = useCallback(() => setView('password'), []);

  const authAttempt = useCallback(async (values:IAuthRequestBody) => {
    addSendingId(FORM_ID);
    const resJson = await login(values);
    removeSendingId(FORM_ID);

    resJson.delay && timer.setTimer(resJson.delay);
    if (resJson.errorStatus === 449) {
      setView('code');
      return;
    }
    if (!resJson.errorStatus) {
      updateUser?.({ ...resJson }, false);
      return;
    }
    setAuthError(resJson.message);
  }, []);
  const requestNewCode = () => authAttempt({ phone });

  const handlePhoneChange = useCallback((event:ChangeEvent<HTMLInputElement>, value:string) => {
    timer.timeLeft && timer.setTimer(0);
    setPhone(value);
  }, []);

  const handleLoginChange = useCallback(() => {
    timer.timeLeft && timer.setTimer(0);
  }, []);

  const handleCodeChange = useCallback(async (event:ChangeEvent<HTMLInputElement>, value:string) => {
    setCode(value);

    if (value.length === 4) {
      const resJson = await login({ phone, code: value });

      if (!resJson.errorStatus) {
        updateUser?.({ ...resJson }, false);
        return;
      }
      setAuthError(resJson.message);
      resJson.delay && timer.setTimer(resJson.delay);
    }
  }, [phone]);

  useEffect(() => {
    setAuthError('');
  }, [phone, view, code]);

  return (
    <div className={styles.root}>
      { view === 'phone' &&
        <div className={styles.loginView}>
          <h1>Вход</h1>
          <Paragraph>Введи свой номер телефона, мы отправим на него код для входа.</Paragraph>
          <Form<IAuthRequestBody>
            onSubmit={authAttempt}
            validate={authValidate}
            submitBtn="Отправить код"
            className={styles.form}
            disabled={!!timer.timeLeft}
            formId={FORM_ID}
          >
            { ({ errors }:IFormRenderProps<IAuthRequestBody>) => (
              <>
                <Delimeter size="xxs"/>
                <Delimeter size="m"/>
                <Input
                  name="phone"
                  defaultValue={phone}
                  errors={errors.phone || authError}
                  onChange={handlePhoneChange}
                  mask="+7 999 999-99-99"/>
              </>
            )}
          </Form>
          <Delimeter size="s"/>
          <TextLink onClick={showPasswordView}>Ввести пароль</TextLink>
        </div>
      }

      { view === 'password' &&
        <div className={styles.loginView}>
          <h1>Вход</h1>
          <Form<IAuthRequestBody>
            onSubmit={authAttempt}
            validate={authValidate}
            submitBtn="Войти"
            className={styles.form}
            disabled={!!timer.timeLeft}
            formId={FORM_ID}
          >
            { ({ errors }:IFormRenderProps<IAuthRequestBody>) => (
              <>
                <Delimeter size="xxs"/>
                <Input
                  placeholder="Номер телефона или email"
                  name="login"
                  errors={errors.login || authError}
                  onChange={handleLoginChange}
                  optionalMask={['+7 999 999-99-99']}/>
                <Delimeter/>
                <Input
                  placeholder="Пароль"
                  name="password"
                  errors={errors.password}
                  type="password"
                  password/>
              </>
            )}
          </Form>
          {/* <Delimeter size="s"/>
          <Link onClick={showPhoneView}>Войти с помощью смс</Link> */}
        </div>
      }

      { view === 'code' &&
        <div className={styles.codeView}>
          <h1>Введи код из смс</h1>
          <Paragraph>Мы отправили смс с кодом на номер { phone }. <a onClick={showPhoneView}>Изменить</a></Paragraph>
          <Delimeter size="l"/>
          <DigitCodeInput name="code" onChange={handleCodeChange} errors={authError}/>
          <Delimeter size="l"/>
        </div>
      }

      { view === 'code' && !timer.timeLeft &&
        <>
          <Delimeter size="s"/>
          <Paragraph size="s">
            <a onClick={requestNewCode}>Запросить новый код</a>
          </Paragraph>
        </>
      }
      { !!timer.timeLeft &&
        <>
          <Delimeter size="s"/>
          <Paragraph size="s" grey>
            {view === 'password' ? 'Повторите вход' : 'Запросить новый код можно'} через {pluralize(timer.timeLeft, 'секунду', 'секунды', 'секунд')[1]}
          </Paragraph>
        </>
      }
    </div>
  );
};