import classNames from 'classnames';
import { AnchorHTMLAttributes, MouseEvent } from 'react';
import { LinkProps, useNavigate } from 'react-router-dom';

import styles from './text-link.module.styl';

type ITextLink = Partial<LinkProps> & AnchorHTMLAttributes<HTMLAnchorElement> & {
  text?: string
  noIndex?: boolean
  colored?: boolean
  children?: React.ReactNode
};

export const TextLink:React.FC<ITextLink> = ({
  to, onClick, href, noIndex, state, text, children, className, colored = true,
  ...props
}) => {
  const navigate = useNavigate();

  const handleClick = (e:MouseEvent<HTMLAnchorElement>) => {
    const event = e;

    if (href && !href.startsWith('/') || noIndex) return;

    if (to) {
      navigate(to, { state });
      return;
    }

    e.stopPropagation();
    e.preventDefault();
    onClick?.(event);
  };

  return (
    <a
      {...props}
      {...(noIndex ? { rel: 'noreferrer noopener' } : {})}
      href={href}
      onClick={handleClick}
      className={classNames(className, styles.root, {
        [styles.colored]: colored
      })}
    >
      { text || children }
    </a>
  );
};
